<template>
  <div class="reset-password">
    <Header />
    <b-container class="reset-password-container">
      <b-row class="justify-content-center">
        <div>
          <b-card-group deck>
            <b-card>
              <h1>Reset Password</h1>
              <template>
                <div>
                  <form @submit.prevent="handleSubmit">
                    <h3 class="reset-password-label">Email</h3>
                    <vs-input
                      id="email"
                      type="email"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="email"
                      required
                    />
                    <h3 class="reset-password-label">Password Baru</h3>
                    <div class="my-2">
                      <small
                        ><i
                          >*password minimal 8 karakter, mengandung minimal 1
                          huruf kapital dan 1 angka</i
                        >
                      </small>
                    </div>
                    <div class="row">
                      <div class="col-9">
                        <vs-input
                          v-show="!showPass"
                          id="password"
                          type="password"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password"
                          required
                        />
                        <vs-input
                          v-show="showPass"
                          id="password"
                          type="text"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password"
                          required
                        />
                      </div>
                      <div class="col-3">
                        <b-button
                          @click="showPass = !showPass"
                          block
                          class="btn-show-password py-2"
                        >
                          <div v-show="!showPass">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showPass">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>
                    <div class="error my-2" v-if="!$v.password.minLength">
                      <p>
                        Password minimal
                        {{ $v.password.$params.minLength.min }} karakter.
                      </p>
                    </div>

                    <h3 class="reset-password-label">Konfirmasi Password</h3>
                    <div class="row">
                      <div class="col-9">
                        <vs-input
                          v-show="!showConfirmationPass"
                          id="password_confirmation"
                          type="password"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password_confirmation"
                          required
                        />
                        <vs-input
                          v-show="showConfirmationPass"
                          id="password_confirmation"
                          type="text"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          v-model="password_confirmation"
                          required
                        />
                      </div>
                      <div class="col-3">
                        <b-button
                          @click="showConfirmationPass = !showConfirmationPass"
                          block
                          class="btn-show-password py-2"
                        >
                          <div v-show="!showConfirmationPass">
                            <b-icon icon="eye"></b-icon>
                          </div>
                          <div v-show="showConfirmationPass">
                            <b-icon icon="eye-slash"></b-icon>
                          </div>
                        </b-button>
                      </div>
                    </div>

                    <div
                      class="error my-2"
                      v-if="!$v.password_confirmation.sameAsPassword"
                    >
                      <p>Password yang Anda masukkan tidak sama.</p>
                    </div>
                    <div class="mb-4"></div>

                    <!-- <div class="my-2" v-if="failedResetPassword">
                      <p>
                        Email dan password tidak sesuai <br />
                        Silahkan cek kembali email dan password Anda
                      </p>
                    </div> -->

                    <div class="pt-2">
                      <b-button
                        block
                        :disabled="loading"
                        type="submit"
                        @click="handleSubmit()"
                        class="btn-tertiary py-2"
                      >
                        <div v-if="loading">
                          <div class="spinner-border spinner-border-sm"></div>
                          Loading
                        </div>
                        <div v-else>Submit</div>
                      </b-button>
                    </div>
                  </form>
                </div>
              </template>
            </b-card>
          </b-card-group>
        </div>
      </b-row>

      <div class="modal-reset-password">
        <b-modal
          id="modal-success-reset-password"
          v-model="successResetPassword"
          size="lg"
          class="modal-success-reset-password"
          centered
          hide-footer
        >
          <div class="d-block text-center">
            <img src="@/assets/img/ekyc/verification-proccess.svg" alt="" />
            <h1 class="p-4 my-2 text-dark">
              Permohonan Reset Password Berhasil
            </h1>
            <p class="text-dark">
              Silahkan lakukan login<br />dengan password baru Anda.
            </p>
          </div>
        </b-modal>
      </div>

      <div class="modal-reset-password">
        <b-modal
          id="modal-success-reset-password"
          v-model="failedResetPassword"
          size="lg"
          class="modal-success-reset-password"
          centered
          hide-footer
          no-close-on-backdrop
        >
          <div class="d-block text-center">
            <img src="@/assets/img/ekyc/failed.svg" alt="" />
            <h1 class="p-4 my-2 text-dark">
              Ups! <br />
              Reset Password Gagal
            </h1>
            <p class="text-dark">
              Ada kesalahan saat reset password.<br />Mohon hubungi customer
              Care.
            </p>
            <b-row class="justify-content-center">
              <b-button
                href="#"
                block
                class="mt-3 mx-2 btn-tertiary py-2"
                >Hubungi customer Care</b-button
              >
            </b-row>
          </div>
        </b-modal>
      </div>
    </b-container>
    <Footer4 />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer4 from "@/components/partials/footer/Footer4.vue";
import { sameAs, minLength } from "vuelidate/lib/validators";
import instance from "@/utils/api";

let token = new URL(location.href).searchParams.get("token");
let email = new URL(location.href).searchParams.get("email");

export default {
  name: "ResetPassword",
  metaInfo: {
    title: "Reset Password | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer4,
  },
  data() {
    return {
      successResetPassword: false,
      failedResetPassword: false,
      token: token,
      email: email,
      password: "",
      password_confirmation: "",
      loading: false,
      processing: false,
      showPass: false,
      showConfirmationPass: false,
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
    }),
  },
  validations: {
    password: {
      minLength: minLength(8),
    },
    password_confirmation: {
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    handleSubmit() {
      if (!this.processing) {
        this.processing = true;
        let data = new FormData();
        data.append("email", this.email);
        data.append("password", this.password);
        data.append("password_confirmation", this.password_confirmation);
        data.append("token", this.token);

        let axiosConfig = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        instance
          .post(`${this.app_url}2825`, data, axiosConfig)
          .then((res) => {
            if (res.data.meta.code == 255) {
              this.failedResetPassword = true;
              this.processing = false;
            } else {
              this.successResetPassword = true;

              this.loading = !false;
              window.setTimeout(function () {
                window.location.href = "/";
              }, 3000);
              this.processing = false;
            }
          })
          .catch(() => {
            this.failedResetPassword = true;
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  background: #f3f4f6;
}
.reset-password-container {
  padding-top: 140px;
  padding-bottom: 140px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;

  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #4b5563;
}

p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #e53e3e;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: #4f566b;
}

.error {
  p {
    font-family: $inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #e53e3e;
  }
}
.vs-con-input-label {
  width: 100%;
}

.reset-password-label {
  padding-top: 16px;
}

.reset-password-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #6b7280;
  text-decoration: none;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #3c4257;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  padding: 48px;
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
  width: 500px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

@media only screen and (min-width: 1366px) {
  .reset-password-container {
    padding-bottom: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .reset-password-container {
    padding-bottom: 60px;
  }
  .back-nav {
    padding: 0px 240px 20px 0px;
  }
  .card-body {
    width: 100%;
  }
}
</style>